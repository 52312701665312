import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _72bcb0ab = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _943e25a2 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _28243323 = () => interopDefault(import('../pages/live.vue' /* webpackChunkName: "pages/live" */))
const _46495ea8 = () => interopDefault(import('../pages/live/_hall.vue' /* webpackChunkName: "pages/live/_hall" */))
const _3fc90ee6 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _0dfc5fe2 = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _bc4f2440 = () => interopDefault(import('../pages/program/index.vue' /* webpackChunkName: "pages/program/index" */))
const _1a65194e = () => interopDefault(import('../pages/speakers/index.vue' /* webpackChunkName: "pages/speakers/index" */))
const _b621cec0 = () => interopDefault(import('../pages/user/index.ts' /* webpackChunkName: "pages/user/index" */))
const _8fb2ea3e = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _ba81d930 = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _5653f680 = () => interopDefault(import('../pages/auth/impersonalize.vue' /* webpackChunkName: "pages/auth/impersonalize" */))
const _c670af8e = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _7578b519 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _9c21ecf0 = () => interopDefault(import('../pages/auth/registration-iframe.vue' /* webpackChunkName: "pages/auth/registration-iframe" */))
const _6350b84a = () => interopDefault(import('../pages/user/calendar.vue' /* webpackChunkName: "pages/user/calendar" */))
const _18522d16 = () => interopDefault(import('../pages/user/chat.vue' /* webpackChunkName: "pages/user/chat" */))
const _5b985fbc = () => interopDefault(import('../pages/user/payment/index.vue' /* webpackChunkName: "pages/user/payment/index" */))
const _d03070c2 = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _879d6764 = () => interopDefault(import('../pages/user/recommended.vue' /* webpackChunkName: "pages/user/recommended" */))
const _477c481d = () => interopDefault(import('../pages/user/payment/participant.vue' /* webpackChunkName: "pages/user/payment/participant" */))
const _4d052866 = () => interopDefault(import('../pages/user/profile/edit.vue' /* webpackChunkName: "pages/user/profile/edit" */))
const _e55c45b0 = () => interopDefault(import('../pages/auth/oauth/_provider.vue' /* webpackChunkName: "pages/auth/oauth/_provider" */))
const _000a09a6 = () => interopDefault(import('../pages/news/_alias.vue' /* webpackChunkName: "pages/news/_alias" */))
const _75ebbeb3 = () => interopDefault(import('../pages/program/_alias.vue' /* webpackChunkName: "pages/program/_alias" */))
const _0ef48105 = () => interopDefault(import('../pages/speakers/_alias.vue' /* webpackChunkName: "pages/speakers/_alias" */))
const _30b84c4f = () => interopDefault(import('../pages/visit/_id.vue' /* webpackChunkName: "pages/visit/_id" */))
const _8a077b90 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _72bcb0ab,
    name: "index___en"
  }, {
    path: "/faq",
    component: _943e25a2,
    name: "faq___ru___default"
  }, {
    path: "/live",
    component: _28243323,
    name: "live___ru___default",
    children: [{
      path: ":hall?",
      component: _46495ea8,
      name: "live-hall___ru___default"
    }]
  }, {
    path: "/news",
    component: _3fc90ee6,
    name: "news___ru___default"
  }, {
    path: "/partners",
    component: _0dfc5fe2,
    name: "partners___ru___default"
  }, {
    path: "/program",
    component: _bc4f2440,
    name: "program___ru___default"
  }, {
    path: "/ru",
    component: _72bcb0ab,
    name: "index___ru"
  }, {
    path: "/speakers",
    component: _1a65194e,
    name: "speakers___ru___default"
  }, {
    path: "/user",
    component: _b621cec0,
    name: "user___ru___default"
  }, {
    path: "/users",
    component: _8fb2ea3e,
    name: "users___ru___default"
  }, {
    path: "/auth/change-password",
    component: _ba81d930,
    name: "auth-change-password___ru___default"
  }, {
    path: "/auth/impersonalize",
    component: _5653f680,
    name: "auth-impersonalize___ru___default"
  }, {
    path: "/auth/login",
    component: _c670af8e,
    name: "auth-login___ru___default"
  }, {
    path: "/auth/registration",
    component: _7578b519,
    name: "auth-registration___ru___default"
  }, {
    path: "/auth/registration-iframe",
    component: _9c21ecf0,
    name: "auth-registration-iframe___ru___default"
  }, {
    path: "/en/faq",
    component: _943e25a2,
    name: "faq___en"
  }, {
    path: "/en/live",
    component: _28243323,
    name: "live___en",
    children: [{
      path: ":hall?",
      component: _46495ea8,
      name: "live-hall___en"
    }]
  }, {
    path: "/en/news",
    component: _3fc90ee6,
    name: "news___en"
  }, {
    path: "/en/partners",
    component: _0dfc5fe2,
    name: "partners___en"
  }, {
    path: "/en/program",
    component: _bc4f2440,
    name: "program___en"
  }, {
    path: "/en/speakers",
    component: _1a65194e,
    name: "speakers___en"
  }, {
    path: "/en/user",
    component: _b621cec0,
    name: "user___en"
  }, {
    path: "/en/users",
    component: _8fb2ea3e,
    name: "users___en"
  }, {
    path: "/ru/faq",
    component: _943e25a2,
    name: "faq___ru"
  }, {
    path: "/ru/live",
    component: _28243323,
    name: "live___ru",
    children: [{
      path: ":hall?",
      component: _46495ea8,
      name: "live-hall___ru"
    }]
  }, {
    path: "/ru/news",
    component: _3fc90ee6,
    name: "news___ru"
  }, {
    path: "/ru/partners",
    component: _0dfc5fe2,
    name: "partners___ru"
  }, {
    path: "/ru/program",
    component: _bc4f2440,
    name: "program___ru"
  }, {
    path: "/ru/speakers",
    component: _1a65194e,
    name: "speakers___ru"
  }, {
    path: "/ru/user",
    component: _b621cec0,
    name: "user___ru"
  }, {
    path: "/ru/users",
    component: _8fb2ea3e,
    name: "users___ru"
  }, {
    path: "/user/calendar",
    component: _6350b84a,
    name: "user-calendar___ru___default"
  }, {
    path: "/user/chat",
    component: _18522d16,
    name: "user-chat___ru___default"
  }, {
    path: "/user/payment",
    component: _5b985fbc,
    name: "user-payment___ru___default"
  }, {
    path: "/user/profile",
    component: _d03070c2,
    name: "user-profile___ru___default"
  }, {
    path: "/user/recommended",
    component: _879d6764,
    name: "user-recommended___ru___default"
  }, {
    path: "/en/auth/change-password",
    component: _ba81d930,
    name: "auth-change-password___en"
  }, {
    path: "/en/auth/impersonalize",
    component: _5653f680,
    name: "auth-impersonalize___en"
  }, {
    path: "/en/auth/login",
    component: _c670af8e,
    name: "auth-login___en"
  }, {
    path: "/en/auth/registration",
    component: _7578b519,
    name: "auth-registration___en"
  }, {
    path: "/en/auth/registration-iframe",
    component: _9c21ecf0,
    name: "auth-registration-iframe___en"
  }, {
    path: "/en/user/calendar",
    component: _6350b84a,
    name: "user-calendar___en"
  }, {
    path: "/en/user/chat",
    component: _18522d16,
    name: "user-chat___en"
  }, {
    path: "/en/user/payment",
    component: _5b985fbc,
    name: "user-payment___en"
  }, {
    path: "/en/user/profile",
    component: _d03070c2,
    name: "user-profile___en"
  }, {
    path: "/en/user/recommended",
    component: _879d6764,
    name: "user-recommended___en"
  }, {
    path: "/ru/auth/change-password",
    component: _ba81d930,
    name: "auth-change-password___ru"
  }, {
    path: "/ru/auth/impersonalize",
    component: _5653f680,
    name: "auth-impersonalize___ru"
  }, {
    path: "/ru/auth/login",
    component: _c670af8e,
    name: "auth-login___ru"
  }, {
    path: "/ru/auth/registration",
    component: _7578b519,
    name: "auth-registration___ru"
  }, {
    path: "/ru/auth/registration-iframe",
    component: _9c21ecf0,
    name: "auth-registration-iframe___ru"
  }, {
    path: "/ru/user/calendar",
    component: _6350b84a,
    name: "user-calendar___ru"
  }, {
    path: "/ru/user/chat",
    component: _18522d16,
    name: "user-chat___ru"
  }, {
    path: "/ru/user/payment",
    component: _5b985fbc,
    name: "user-payment___ru"
  }, {
    path: "/ru/user/profile",
    component: _d03070c2,
    name: "user-profile___ru"
  }, {
    path: "/ru/user/recommended",
    component: _879d6764,
    name: "user-recommended___ru"
  }, {
    path: "/user/payment/participant",
    component: _477c481d,
    name: "user-payment-participant___ru___default"
  }, {
    path: "/user/profile/edit",
    component: _4d052866,
    name: "user-profile-edit___ru___default"
  }, {
    path: "/en/user/payment/participant",
    component: _477c481d,
    name: "user-payment-participant___en"
  }, {
    path: "/en/user/profile/edit",
    component: _4d052866,
    name: "user-profile-edit___en"
  }, {
    path: "/ru/user/payment/participant",
    component: _477c481d,
    name: "user-payment-participant___ru"
  }, {
    path: "/ru/user/profile/edit",
    component: _4d052866,
    name: "user-profile-edit___ru"
  }, {
    path: "/en/auth/oauth/:provider?",
    component: _e55c45b0,
    name: "auth-oauth-provider___en"
  }, {
    path: "/ru/auth/oauth/:provider?",
    component: _e55c45b0,
    name: "auth-oauth-provider___ru"
  }, {
    path: "/auth/oauth/:provider?",
    component: _e55c45b0,
    name: "auth-oauth-provider___ru___default"
  }, {
    path: "/en/news/:alias",
    component: _000a09a6,
    name: "news-alias___en"
  }, {
    path: "/en/program/:alias",
    component: _75ebbeb3,
    name: "program-alias___en"
  }, {
    path: "/en/speakers/:alias",
    component: _0ef48105,
    name: "speakers-alias___en"
  }, {
    path: "/en/visit/:id?",
    component: _30b84c4f,
    name: "visit-id___en"
  }, {
    path: "/ru/news/:alias",
    component: _000a09a6,
    name: "news-alias___ru"
  }, {
    path: "/ru/program/:alias",
    component: _75ebbeb3,
    name: "program-alias___ru"
  }, {
    path: "/ru/speakers/:alias",
    component: _0ef48105,
    name: "speakers-alias___ru"
  }, {
    path: "/ru/visit/:id?",
    component: _30b84c4f,
    name: "visit-id___ru"
  }, {
    path: "/news/:alias",
    component: _000a09a6,
    name: "news-alias___ru___default"
  }, {
    path: "/program/:alias",
    component: _75ebbeb3,
    name: "program-alias___ru___default"
  }, {
    path: "/speakers/:alias",
    component: _0ef48105,
    name: "speakers-alias___ru___default"
  }, {
    path: "/visit/:id?",
    component: _30b84c4f,
    name: "visit-id___ru___default"
  }, {
    path: "/en/*",
    component: _8a077b90,
    name: "all___en"
  }, {
    path: "/ru/*",
    component: _8a077b90,
    name: "all___ru"
  }, {
    path: "/",
    component: _72bcb0ab,
    name: "index___ru___default"
  }, {
    path: "/*",
    component: _8a077b90,
    name: "all___ru___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
